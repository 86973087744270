.int-navbar {
    display: flex;
    align-items: center;
}

.int-navbar > * {
    margin-left: var(--int-spacing-3);
}

.int-navbar-item {
    position: relative;
    color: var(--white);
}

.int-navbar-item.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: var(--int-colors-orange-400);
}

.int-active-link {
    color: var(--int-colors-white);
    text-decoration: none;
}

.light .int-active-link {
    color: var(--int-colors-black);
}
