.int-alert {
    width: 100%;
    padding: 0.75rem 1rem;
    background-color: var(--int-colors-red-500);
    text-align: center;
    font-weight: 600;
}

.is-loading {
    background-color: var(--int-colors-green-400);
}