.int-tags {
  background-color: var(--int-colors-white);
  border-radius: 0.375rem;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.int-tag {
  padding: 0.25rem 0.5rem;
  height: 2rem;
  background: var(--int-colors-orange-400);
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin: 4px;
  justify-content: center;
  font-weight: 500;
  color: var(--int-colors-gray-800);
}

.int-tag svg {
  margin-left: 4px;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.3s all
}

.int-tag svg:hover {
  background-color: rgba(0, 0, 0, 0.2)
}

.int-tags input {
  flex: 1;
  min-width: 30%;
}

.int-input:focus {
  box-shadow: none;
}
