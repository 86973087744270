.int-list-group {
    width: 100%;
    max-width: 100%;

    display: flex;  
    flex-direction: column;
    align-items: flex-start;

    list-style: none;

    margin-left: auto;
    margin-right: auto;
}

.int-list-group > li {
    width: 100%;
    padding: var(--int-spacing-4) var(--int-spacing-6);
    color: var(--int-colors-white);
    border: 1px solid var(--int-colors-white);
}

.light .int-list-group > li {
    color: var(--int-colors-black);
    border: 1px solid var(--int-colors-black);
}

.int-list-group > li:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.int-list-group > li:last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}