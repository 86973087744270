.int-loader {
    border: 3px solid transparent;
    border-radius: 50%;
    border-top: 3px solid var(--int-colors-orange-400);
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 0.5s linear infinite;
}

.int-loader-sm {
    width: 16px;
    height: 16px;
    border: 1px solid transparent;
    border-radius: 50%;
    border-top: 1px solid var(--int-colors-orange-400);
}

.int-loader-full-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.9);
    z-index: 1000;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
