.multiple-check-container {
    width: 100%;
    display: grid;
    grid-gap: 8px;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -8px;
    padding: 0 8px 12px 8px;
    border: 2px solid rgba(146, 149, 152, 0.2);
    border-radius: 6px;
    margin-top: 12px;
}

.light .multiple-check-container {
    border: 2px solid rgba(0, 0, 0, 0.4);
}

.multiple-check-container > div {
    padding: 6px 16px;
    border: 2px solid #ffffff;
    border-radius: 32px;
    margin-right: 8px;
    margin-top: 12px;
    cursor: pointer;
    font-weight: 600;
    opacity: 0.8;
    transition: all 0.2s;
}

.light .multiple-check-container > div {
    border: 2px solid #000000;
}

.multiple-check-container > div:hover {
    opacity: 1;
}

.multiple-check-container > div.active {
    border: 2px solid #fe7b1d;
    background: #fe7b1d;
    opacity: 1;
}

.multiple-check-container > div > svg {
    margin-right: 8px;
}
