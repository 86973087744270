.int-tab-style {
    width: 100%;
    min-width: 150px;
    display: block;
    padding: 0.875rem 1rem;
    transition-duration: 200ms;
    color: var(--int-colors-white);
    background-color: var(--int-colors-gray-800);
    border-top: 2px solid var(--int-colors-orange-400);
    border-left: 2px solid var(--int-colors-orange-400);
    border-right: 2px solid var(--int-colors-orange-400);
    border-radius: 0.25rem;
}

.light .int-tab-style {
    color: var(--int-colors-black);
    background-color: var(--int-colors-gray-200);
}

.int-tab-style:hover {
    background-color: var(--int-colors-orange-400);
}

.int-tab-content-border {
    border: 2px solid var(--int-colors-orange-400);
}

.int-hide-tab {
    display: none;
}

.int-show-tab {
    display: block;
}

.int-m-0-force {
    margin: 0 !important;
}

.int-tab-margin {
    margin-left: -10px;
}

.int-tab-margin:first-child {
    margin-left: 0;
}