:root {
    /* Colors */
    --int-colors-yellow-100: #F7F5C4;
    --int-colors-yellow-200: #F6EEA2;
    --int-colors-yellow-300: #F7F28A;
    --int-colors-yellow-400: #F5ED71;
    --int-colors-yellow-500: #F1E956;
    --int-colors-yellow-600: #F1E82E;
    --int-colors-yellow-700: #F3E802;
    --int-colors-yellow-800: #F2E500;
    --int-colors-yellow-900: #FFF500;  
}

.int-columns-wrapper {
    margin-top: 1rem;

    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 0.5rem;

    list-style: none;
}

.int-columns-wrapper li {
    padding: 1rem 1.25rem;
    background-color: var(--int-colors-gray-800);
    border: 2px solid var(--int-colors-orange-400);
    border-radius: 0.25rem;
}

.light .int-columns-wrapper li {
    background-color: var(--int-colors-gray-100);
}

.int-table-resizer {
    display: block;
    position: absolute;
    cursor: col-resize;
    width: 8px;
    right: 0px;
    top: 0;
    z-index: 1;
    border-right: 2px solid transparent;
    transition: 0.2s all;
}
  
.int-table-resizer:hover {
    border-color: var(--int-colors-gray-400)
}
  
.int-table-resizer.isResizing {
    border-color: var(--int-colors-cyan-200);
}

thead,
tbody,
tfoot{
    width: 100%;
}