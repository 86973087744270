/*Classes do timepicker*/
.container-picker-horario {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.box-time-picker {
    display: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-radius: 6px;
    background: #ffffff;
    position: absolute !important;
    margin-top: 12% !important;
    margin-left: 0.55%;
    z-index: 3;
}

.box-select-time {
    width: 55px;
}
