.int-form-organization {
  width: 9%;
  height: 3vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.int-input-organization {
  width: 100%;
  height: 90%;
  position: relative;
  display: flex;
}

.int-form-search::-webkit-outer-spin-button,
.int-form-search::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.int-form-search[type=number] {
  -moz-appearance: textfield;
}

.int-form-search {
  width: 100%;
  height: 100%;
  background-color: #444040;
  color: white;
  border-radius: 7px;
  outline: none;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  position: relative;
  font-size: 1rem;
  font-weight: 500;
}

.light .int-form-search {
  background-color: #e4e4e4;
  color: black;
}

.int-cont-alert {
  position: absolute;
  z-index: 2;
  margin-top: 9vh;
  transform: rotate(180deg);
  margin-right: -3vh;
}

.box-error-message {
  background: #C53030;
  border-radius: 7px;
  width: 12vw;
  height: 5vh;
  position: relative;
}

.triangle {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #C53030;
  bottom: -9px;
  left: 16%;
}

.error-message {
  width: 100%;
  height: 100%;
  transform: rotate(180deg) !important;
  font-size: 95% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1024px) {
  .int-form-organization{
    width: 5%;
  }

  .int-input-organization{
    width: 100%;
  }
  .int-form-search{
    padding-inline-start: 0.5rem;
  }
  .int-group-right-icon {
    width: var(--int-sizes-4);
  }
}

@media (max-width: 1240px) {
  .int-form-organization{
    width: 6%;
  }

  .int-input-organization{
    width: 100%;
  }
  .int-form-search{
    padding-inline-start: 0.5rem;
  }
  .int-group-right-icon {
    width: var(--int-sizes-4);
  }
}

@media (max-width: 1477px) {
  .error-message{
    font-size: 90% !important
  }
}

@media (max-width: 1900px) {
  .triangle{
    left: 21%;
  }
}