.int-navbar-dropdown-list {
    position: absolute;
    z-index: 1000;
    top: 3rem;
    left: 0;
    display: none;
    width: max-content;
    min-width: 10rem;
    padding: .5rem 0;
    margin: 0;
    font-size: 1rem;
    color: var(--int-colors-white);
    text-align: left;
    list-style: none;
    background-color: var(--int-colors-gray-800);
    background-clip: padding-box;
    border: 2px solid var(--int-colors-gray-500);
    border-radius: .25rem;
    flex-direction: column;
    align-items: flex-start;
}

.light .int-navbar-dropdown-list {
    background-color: var(--int-colors-gray-100);

}

.int-dropdown-right > .int-navbar-dropdown-list{
    left: auto;
    right: 0;
}

.active > .int-navbar-dropdown-list {
    display: flex;
}

.int-navbar-dropdown-list > a {
    display: inline-block;
    padding: 0.5rem 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: 200ms;
}

.int-active-link {
    color: var(--int-colors-white);
}

.light .int-active-link {
    color: var(--int-colors-black);
}