.int-radioButton {
    display: flex;
    flex-direction: row;
    gap: .8rem;
}

.int-radioButton input[type="radio"] {  
    margin-right: .2rem;
  }

  .int-radioButton input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

.int-radioButton input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: var(--int-colors-orange-600);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}