#csv-file,
.imported {
  position: relative;
  height: 17rem;
  width: 100%;

  border: 2px dashed #cccccc66;
  border-radius: 2rem;

  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imported {
  border: 2px dashed var(--blue);
}

#csv-file input[type="file"] {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}

#csv-file .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
}

#csv-file .content span,
#csv-file .content b {
  text-align: center;
}
