.int-tooltip {
    position: relative;
    display: inline-flex;
}

.int-tooltip > div {
    width: 17rem;
    max-width: 18rem;

    position: absolute;
    top: 150%;
    font-weight: var(--int-fontWeights-semibold);
    border-radius: 10px;
    z-index: 1;

    padding: 10px;

    color: var(--int-colors-white);
    background-color: var(--int-colors-orange-600);
    text-align: center;

    visibility: hidden;
    opacity: 0;
    transition: 200ms;
}
.int-tooltip-label {
    overflow-y: auto;
    max-height: 120px;
    white-space: normal;
}

.int-tooltip-label::-webkit-scrollbar {
    width: 8px;
    border-radius: 30px;
}

.int-tooltip-label::-webkit-scrollbar-thumb {
    background: var(--int-colors-gray-800);
    border-radius: 30px;
}

.int-tooltip-label::-webkit-scrollbar-track {
    background-color: transparent;
}

.int-tooltip-arrow {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--int-colors-orange-600);
    top: -10px;
}

.int-tooltip:hover > div, .int-no-icon-tooltip:hover {
    visibility: visible;
    opacity: 1;
}

/* No icon tooltip */

.int-no-icon-tooltip {
    max-width: 16rem;

    position: absolute;
    z-index: 1;
    padding: 0.25rem;

    color: var(--int-colors-white);
    background-color: var(--int-colors-gray-900);
    text-align: center;

    opacity: 0;
    transition: 200ms;
}

.light .int-tooltip > span {
    color: var(--int-colors-black);
    background-color: var(--int-colors-gray-100);
}