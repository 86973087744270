.int-breadcrumb {
    list-style: none;

    display: flex;
    align-items: center;
}

.int-breadcrumb > li {
    display: flex;
    align-items: center;
}

.int-breadcrumb > li + li {
    margin-left: 0.25rem;
}

.int-breadcrumb > li + li::before {
    content: '/';
    margin-right: 0.25rem;
}